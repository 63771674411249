import React from "react";
import Layout from "../components/Layout";
import data from "../../content/resources/sector_educativo.json";
import {Link} from "gatsby";


export default function Educativo() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(/../images/sectores/2.jpg)`}}
                    >
                        <h1>Educativo</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Conscientes de la necesidad de innovación de las instituciones educativas en todos los
                            niveles y modalidades de América Latina y el Caribe hemos desarrollado por más tres décadas
                            un portafolio de soluciones a medida del sector educativo, respondiendo a las demandas
                            específicas e innovando con recursos de información y plataformas que sin lugar a dudas se
                            convierten en un aliado los actores involucrados en el proceso de enseñanza – aprendizaje.
                        </p>
                    </div>
                    <section className="tiles">
                        {data.map((service) => {
                            return (
                                <article key={service.id} className="style1">
                                    <span className="image">
                                        <img
                                            src={service.image}
                                            alt={service.alt}/>
                                    </span>
                                    <Link to={service.path} state={{service: service}}>
                                        <h2>{service.name}</h2>
                                        <div className="content">
                                            <p>
                                                {service.description}
                                            </p>
                                        </div>
                                    </Link>
                                </article>
                            )
                        })}
                    </section>
                </div>
            </div>

        </Layout>
    )
}